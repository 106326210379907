<template>
  <div id="data-list-list-view">
    <feather-icon
      icon="ArrowLeftIcon"
      class="mb-4"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.push('/Hospital')"
    />

    <hospital-Tab class="mt-4" :mainTabName="'PatientSurgeries'" />
    <!-- search card -->
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Hospital Name") }}</label>
          <vs-input
            v-model="searchModel.HospitalName"
            class="w-full"
            name="HospitalName"
          />
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Patient Name") }}</label>
          <vs-input
            v-model="searchModel.PatientName"
            class="w-full"
            name="PatientName"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="searchModel.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="searchModel.DateTo"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>

          <v-select
            label="Name"
            class="w-full"
            v-model="searchModel.StatusID"
            :options="status"
            :reduce="(stat) => stat.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="searchModel.PaymentStatusID"
            :options="PaymentStatus"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <div class="vx-col w-full">
          <vs-button
            class="my-6 rounded-sm"
            @click="getPatientReservationSurgeries()"
            color="warning"
           
            >{{ $t("Search") }}</vs-button
          >
          <vs-button
            style="margin-left: 15px"
            class="my-6"
            @click="resetData"
            >{{ $t("Reset") }}</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div>
      <vx-card
        class="flex-1"
        v-bind:title="$t('Patient Reservation Surgeries')"
      >
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="PatientReservationSurgeries"
        >
          <!-- header -->
          <template slot="thead">
            <vs-th> {{ $t("Surgery") }}</vs-th>
            <!-- <vs-th> {{ $t("Doctor") }}</vs-th> -->
            <vs-th> {{ $t("Patient") }}</vs-th>

            <vs-th> {{ $t("Status") }}</vs-th>
            <vs-th> {{ $t("Payment Status") }}</vs-th>

            <vs-th> {{ $t(" From Date") }}</vs-th>
            <vs-th> {{ $t(" To Date") }}</vs-th>
            <vs-th> {{ $t(" Surgery Date") }}</vs-th>
            <vs-th> {{ $t(" Doctor") }}</vs-th>

            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p
                    v-if="tr.HospitalSurgery && tr.HospitalSurgery.Surgery"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.HospitalSurgery.Surgery.Name }}
                  </p>
                </vs-td>
                <!-- <vs-td>
                  <p
                    v-if="tr.HospitalSurgery"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.HospitalSurgery.Doctor.Name }}
                  </p>
                </vs-td> -->
                <vs-td>
                  <p
                    class="product-name font-medium truncate"
                    v-if="tr.Patient"
                  >
                    {{ tr.Patient.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p v-if="tr.Status" class="product-name font-medium truncate">
                    {{ tr.Status.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.PaymentStatus && tr.PaymentStatus.ID ==1"
                    class="product-name font-medium truncate"
                  >
                     {{ $t("PendingProcessingFees") }}
                  </p>
                  <p
                    v-if="tr.PaymentStatus && tr.PaymentStatus.ID !=1"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.PaymentStatus.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.SurgeryDateFrom"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDateFrom }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.SurgeryDateTo"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDateTo }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.SurgeryDate"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDate }}
                  </p>
                </vs-td>
                <vs-td>
                  <p v-if="tr.Doctor" class="product-name font-medium truncate">
                    {{ tr.Doctor.NameEN }}
                  </p>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="editData(tr)"
                  />
                  <!-- <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click="openConfirm(tr)"
              /> -->
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" @click="$router.go(-1)">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import hospitalTab from "@/views/tabs/hospitalTab.vue";
export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    hospitalTab,

    // vSelect,
  },

  computed: {
    PatientReservationSurgeries() {
      return this.$store.state.HospitalList.PatientReservationSurgeries;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    PaymentStatus() {
      if (this.$store.state.PaymentStatusList.PaymentStatus.length > 0) {
        for (
          var counter = 0;
          counter < this.$store.state.PaymentStatusList.PaymentStatus.length;
          counter++
        ) {
          debugger;
          if (
            this.$store.state.PaymentStatusList.PaymentStatus[counter].ID ==
              1 &&
            this.$store.state.PaymentStatusList.PaymentStatus[counter].Name ==
              "Pending"
          ) {
            debugger;
            this.$store.state.PaymentStatusList.PaymentStatus[counter].Name =
              "Pending / Pending Processing Fees";
          }
        }
      }
      return this.$store.state.PaymentStatusList.PaymentStatus;
    },
  },
  data() {
    return {
      searchModel: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: [],
      },

      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},

      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
    };
  },
  methods: {
    editData(item) {
      this.$router.push({
        name: "ReservationSurgery-Details",
        params: { ID: item.ID },
      });
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert,
      });
    },
    acceptAlert() {
      this.$store
        .dispatch(
          "HospitalList/DeletePatientReservationSurgery",
          this.rowDataForDelete
        )
        .then(() => {
          this.getPatientReservationSurgeries();
          this.$vs.notify({
            color: "success",
            title: "Deleted record",
            text: this.$t("DeletedSuccessfully"),
          });
          this.rowDataForDelete = {};
        })
        .catch((err) => {
          console.error(err);
        });
    },
    resetData() {
      this.searchModel = {};
      // this.searchModel.StatusID=null;
      this.searchModel.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.searchModel.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
   const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.getPatientReservationSurgeries(ID, true);
    }
    },
    getPatientReservationSurgeries(ID, IsLoading) {
      var ID = ID;
      if (ID == undefined) {
        ID = this.$route.params.ID;
      }
      this.searchModel.HospitalID = ID;
      if (IsLoading != undefined && IsLoading == true) {
        //  this.searchModel.StatusID=1;
      }
      this.$vs.loading();

      this.$store
        .dispatch(
          "HospitalList/getPatientReservationSurgeries",
          this.searchModel
        )
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    // this.GetSurgery();
    this.resetData();

    if (!moduleStatus.isRegistered) {
      this.$store.registerModule("StatusList", moduleStatus);
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");
    if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule("PaymentStatusList", modulePaymentStatus);
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
